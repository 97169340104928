import * as React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import ArticleCard from "components/ArticleCard"
import { generateTagObjectFromString } from "components/ArticlesGrid"
import styled from "@emotion/styled"
import Container from "../container"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"

export interface IAuthorRef {
	slug: string
	firstName: string
	lastName: string
	title: string
	picture: any
}

export interface IBlogPost {
	id: string
	publishDate: string
	slug: string
	author: string
	title: string
	seo: any
	heroImage: any
	coverImage: any
	content: {
		content: string
	}
	description: {
		description: string
	}
	tags: string[]
	authorRef: IAuthorRef
}

interface IProps {
	allContentfulBlogPost: {
		edges: {
			node: IBlogPost
		}[]
	}
	tags: string[]
}

const containerStyles = css`
	display: flex;
	flex-direction: column;
`

const Title = styled.h2`
	font-size: 3.4em;
	font-weight: 800;
	color: #1a1413;
	margin-bottom: 30px;

	${mediaBreakpoint(BreakPoints.MD)} {
		font-size: 4.8em;
		margin-left: 12px;
	}
`

const PostsContainer = styled.div`
	display: flex;
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.SM)} {
		flex-direction: row;
	}
`

const lastArticleCard = css`
	display: none;

	${mediaBreakpoint(BreakPoints.LG)} {
		display: flex;
	}
`

const articleCard = css`
	flex-basis: calc(100% / 1 - 24px);
	max-width: calc(100% / 1 - 24px);

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-basis: calc(100% / 2 - 24px);
		max-width: calc(100% / 2 - 24px);
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		flex-basis: calc(100% / 3 - 24px);
		max-width: calc(100% / 3 - 24px);
	}
`

const getRandomIntArr = (min: number, max: number, size: number) => {
	const nums = new Set()
	while (nums.size !== size) {
		nums.add(Math.floor(Math.random() * (max - min + 1) + min))
	}

	return nums
}

const NUM_OF_RELATED = 3

const RelatedArticles: React.FC<IProps> = ({ allContentfulBlogPost, tags }) => {
	const filteredBlogPosts = tags.map(tag =>
		allContentfulBlogPost.edges.filter(edge => edge.node.tags?.findIndex(elem => elem === tag) > -1)
	)

	const randomNums = getRandomIntArr(0, filteredBlogPosts[0].length - 1, NUM_OF_RELATED)
	const randomizedPosts = Array.from(randomNums, val => val).map((num: number) => filteredBlogPosts[0][num])

	return (
		<Container css={containerStyles}>
			<Title>Related Articles</Title>
			<PostsContainer>
				{randomizedPosts.map((post, i) => {
					const { id, title, slug, heroImage, description, authorRef, tags: postTags } = post.node
					const isLast = i === NUM_OF_RELATED - 1
					const objectTags = (postTags || []).map(generateTagObjectFromString)

					return (
						<ArticleCard
							tags={objectTags}
							key={id}
							title={title}
							slug={slug}
							heroImage={heroImage}
							description={description?.description}
							authorRef={authorRef}
							customStyle={[isLast && lastArticleCard, articleCard]}
						/>
					)
				})}
			</PostsContainer>
		</Container>
	)
}


export default React.memo(RelatedArticles)
